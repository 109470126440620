import React from 'react';
import MenuItem from './MenuItem';
import MenuCategory from './MenuCategory';

const MenuLinks = ({ toggleModal }) => {
  return (
    <div className="w-full flex flex-col items-start overflow-hidden">
      <MenuCategory title="Home" />
      <MenuItem href="/" label="Home" icon="/assets/internet.png" toggleModal={toggleModal} />

      <MenuCategory title="Learn" />
      <MenuItem href="/learn" label="Crypto Lessons" icon="/assets/school.png" toggleModal={toggleModal} />
      <MenuItem href="/quizzes" label="Crypto Quizzes" icon="/assets/reading.png" toggleModal={toggleModal} />

      <MenuCategory title="Trade" />
      <MenuItem href="/swap" label="Trade Tokenized Assets" icon="/assets/swap.gif" toggleModal={toggleModal} />
      <MenuItem href="/TTD" label="Tokenised Trading Desk" icon="/assets/lock-lines.png" toggleModal={toggleModal} />
      <MenuItem href="/wallet" label="Wallet" icon="/assets/digital-wallet.png" toggleModal={toggleModal} />
      <MenuItem href="/demo" label="Demo Trading" icon="/assets/buy.png" toggleModal={toggleModal} />

      <MenuCategory title="Discover" />
      <MenuItem href="/companies" label="Companies" icon="/assets/company.png" toggleModal={toggleModal} />
      <MenuItem href="/governance" label="Governance" icon="/assets/hammer.png" toggleModal={toggleModal} />
      <MenuItem href="/news" label="News" icon="/assets/news.png" toggleModal={toggleModal} />
      <MenuItem href="/news/calendar" label="Market Calendar" icon="/assets/deadline.png" toggleModal={toggleModal} />

      <MenuCategory title="Token Info" />
      <MenuItem
        href="https://drive.google.com/file/d/13D6wSMP3uWr6auCY2BSoClphd39EqDGT/view?usp=drive_link"
        label="Whitepaper"
        icon="/assets/whitepaper.png"
        toggleModal={toggleModal}
        external
      />
      <MenuItem href="/tokenomics" label="Tokenomics" icon="/assets/tokenomics.gif" toggleModal={toggleModal} />
      <MenuItem href="/roadmap" label="Roadmap" icon="/assets/roadmap.png" toggleModal={toggleModal} />

      <MenuCategory title="Support" />
      <MenuItem href="/support" label="Support" icon="/assets/technical-support.png" toggleModal={toggleModal} />
      <MenuItem href="/about" label="About" icon="/assets/information.png" toggleModal={toggleModal} />
    </div>
  );
};

export default MenuLinks;
