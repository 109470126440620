import React, { useState } from 'react';
import DropdownItem from './DropdownItem';

const TokenInfoDropdown = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex items-center text-white px-2 font-medium h-full cursor-pointer transition-all"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="text-[18px]">Token Info</span>
      <svg
        className={`w-4 h-4 ml-1 transition-transform duration-300 ${
          isHovered ? 'rotate-180' : ''
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>

      <div
        className={`absolute top-full left-0 w-[450px] z-50 transition-all duration-500 ease-out overflow-hidden shadow-xl rounded-xl mt-2 ${
          isHovered ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <DropdownItem
          href="https://drive.google.com/file/d/13D6wSMP3uWr6auCY2BSoClphd39EqDGT/view?usp=drive_link"
          icon="/assets/whitepaper.png"
          title="Whitepaper"
          subtitle="Dive into the detailed documentation about PINX’s goals and technology."
          external
        />
        <DropdownItem
          href="/tokenomics"
          icon="/assets/tokenomics.gif"
          title="Tokenomics"
          subtitle="Learn more about the supply and distribution of PINX tokens."
        />
        <DropdownItem
          href="/roadmap"
          icon="/assets/roadmap.png"
          title="Roadmap"
          subtitle="Check out the future development plans for PINX."
        />
      </div>
    </div>
  );
};

export default TokenInfoDropdown;
