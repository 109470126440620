import React from 'react';
import WalletModalHeader from './WalletModalHeader';
import MenuLinks from './MenuLinks';
import PinxPriceRow from './PinxPriceRow';

interface MobileMenuProps {
  toggleModal: () => void;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MobileMenu: React.FC<MobileMenuProps> = ({ isModalOpen, toggleModal, setIsModalOpen }) => {
  return (
    <div
      className={`
        fixed top-0 left-0 w-full h-full z-[2000] bg-black bg-opacity-95 
        transition-transform duration-300 ease-in-out flex flex-col
        ${isModalOpen ? 'translate-x-0' : 'translate-x-full'}
      `}
    >
      <WalletModalHeader toggleModal={toggleModal} setIsModalOpen={setIsModalOpen} />
      <PinxPriceRow />

      <div className="mt-3 mb-4 flex-1 w-full overflow-y-auto scrollbar-hide">
        <MenuLinks toggleModal={toggleModal} />
      </div>
    </div>
  );
};

export default MobileMenu;
