import React from 'react';
import ConnectWalletButton from '../../Account/ConnectWalletButton';

interface WalletModalHeaderProps {
  toggleModal: () => void;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>; 
}

const WalletModalHeader: React.FC<WalletModalHeaderProps> = ({ toggleModal, setIsModalOpen }) => {
  return (
    <div className="wallet-modal-header">
      <ConnectWalletButton setIsModalOpen={setIsModalOpen} />
      <div className="close-btn" onClick={toggleModal}>X</div>

      <style jsx>{`
        .wallet-modal-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 10px 20px;
          height: 100px;
          background-color: rgba(0, 0, 0, 0.95);
        }

        .close-btn {
          background: linear-gradient(to bottom, #ff1a1a, #800000); /* Red to dark red gradient */
          color: white;
          font-size: 3vw; 
          width: 6vw;
          height: 6vw;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          cursor: pointer;
          transition: background 0.3s ease;
        }

        .close-btn:hover {
          background: linear-gradient(to bottom, #ff4d4d, #4d0000); /* Hover gradient */
        }

        @media (max-width: 800px) {
          .close-btn {
            font-size: 4vw;
            width: 8vw;
            height: 8vw;
          }
        }

        @media (max-width: 600px) {
          .close-btn {
            font-size: 5vw;
            width: 10vw;
            height: 10vw;
          }
        }

        @media (max-width: 400px) {
          .close-btn {
            font-size: 6vw;
            width: 12vw;
            height: 12vw;
          }
        }
      `}</style>
    </div>
  );
};

export default WalletModalHeader;
