import React from 'react';
import { FaTwitter, FaTelegramPlane, FaYoutube, FaApple } from 'react-icons/fa';
import Image from 'next/image';
import Link from 'next/link';

const MobileFooter: React.FC = () => {
  return (
    <>
      <div className="w-full bg-gradient-to-b from-[rgba(80,0,0,0.6)] to-[rgba(0,0,0,0.6)] border-t border-white/20 py-8 px-4">
        <div className="flex flex-col items-center">
          {/* Logo */}
          <div className="flex flex-col items-center">
            <div className="logo-background">
              <Image
                src="/assets/pinx-circle-logo.png"
                alt="PINX Logo"
                width={120}
                height={120}
                className="rounded-full"
              />
            </div>
            <p className="text-white font-bold text-xs sm:text-sm mt-3 text-center">
              Your Gateway to Decentralized Finance
            </p>

            <Link href="https://apps.apple.com/us/app/pinx-global-markets/id6670228875" passHref legacyBehavior>
              <a className="w-full mt-4 mb-3 py-2 px-4 text-white text-xs sm:text-sm bg-gradient-to-b from-zinc-800 to-black border border-white/20 rounded-lg backdrop-blur flex justify-center items-center hover:from-zinc-700 hover:to-zinc-900">
                <FaApple className="mr-2 text-lg" /> Download on the App Store
              </a>
            </Link>

            {/* Socials */}
            <div className="flex justify-center gap-6 text-white text-xl sm:text-2xl mt-3">
              <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
              <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </a>
            </div>
          </div>

          {/* Info Sections */}
          <div className="flex info-sections flex-wrap justify-center gap-6 mt-8 w-full max-w-lg">

            {/* $PINX Info */}
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-white text-xs sm:text-sm md:text-base font-bold underline">$PINX Info</h3>
              <Link href="/tokenomics" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Whitepaper</a>
              </Link>
              <Link href="/tokenomics" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Tokenomics</a>
              </Link>
              <Link href="/roadmap" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Roadmap</a>
              </Link>
            </div>

            {/* Learn */}
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-white text-xs sm:text-sm md:text-base font-bold underline">Learn</h3>
              <Link href="/learn" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Learn</a>
              </Link>
              <Link href="/quizzes" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Quizzes</a>
              </Link>
              <Link href="/about" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">About Us</a>
              </Link>
            </div>

            {/* Social Links */}
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-white text-xs sm:text-sm md:text-base font-bold underline">Social Links</h3>
              <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer" className="text-white text-xs sm:text-sm font-bold hover:underline">
                Twitter
              </a>
              <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer" className="text-white text-xs sm:text-sm font-bold hover:underline">
                Telegram
              </a>
              <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer" className="text-white text-xs sm:text-sm font-bold hover:underline">
                YouTube
              </a>
            </div>

            {/* Discover */}
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-white text-xs sm:text-sm md:text-base font-bold underline">Discover</h3>
              <Link href="/companies" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Companies</a>
              </Link>
              <Link href="/news" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">News</a>
              </Link>
              <Link href="/governance" passHref legacyBehavior>
                <a className="text-white text-xs sm:text-sm font-bold hover:underline">Governance</a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .logo-background {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0 0 20px 8px rgba(249, 0, 143, 0.6), 0 0 40px rgba(139, 33, 223, 0.5);
        }


        @media (max-width: 365px) {
          .info-sections {
            display: grid;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            column-gap: 8px;
            row-gap: 24px;
          }
        }
      `}</style>

    </>
  );
};

export default MobileFooter;
