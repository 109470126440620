import Link from 'next/link';
import Image from 'next/image';

interface DropdownItemProps {
  href: string;
  icon: string;
  title: string;
  subtitle: string;
  external?: boolean;
}

const DropdownItem: React.FC<DropdownItemProps> = ({ href, icon, title, subtitle, external = false }) => {
  const content = (
    <div className="flex items-center py-2 m-2 rounded-lg px-3 bg-gradient-to-b from-[#8c1446] to-[#500028] transition-all shadow-lg hover:from-[#640032] hover:to-[#3c001e] hover:shadow-2xl">
      <div className="flex items-center justify-center w-10 h-10 mr-3">
        <Image src={icon} alt={`${title} Icon`} width={40} height={40} />
      </div>
      <div className="flex-1">
        <div className="text-white text-[19px] mb-1">{title}</div>
        <div className="text-sm text-white text-opacity-60 leading-snug line-clamp-2">{subtitle}</div>
      </div>
      <div className="flex items-center justify-center w-10 h-10 ml-3 fill-white">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
          <path d="M8 5l5 5 -5 5V5z" />
        </svg>
      </div>
    </div>
  );

  return external ? (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="block no-underline text-white"
    >
      {content}
    </a>
  ) : (
    <Link href={href} passHref>
      <a className="block no-underline text-white">{content}</a>
    </Link>
  );
};

export default DropdownItem;
