import React from 'react';
import LaptopFooter from './LaptopFooter';
import MobileFooter from './MobileFooter';
import BelowFooter from './BelowFooter'; 

const Footer: React.FC = () => {
  return (
    <>
      <div className="footer-wrapper">
        <div className="laptop-footer">
          <LaptopFooter />
        </div>
        <div className="mobile-footer">
          <MobileFooter />
        </div>
        <BelowFooter />
      </div>

      <style jsx global>{`
        .laptop-footer {
          display: block;
        }

        .mobile-footer {
          display: none;
        }

        @media (max-width: 950px) {
          .laptop-footer {
            display: none;
          }

          .mobile-footer {
            display: block;
          }
        }
      `}</style>
    </>
  );
};

export default Footer;
