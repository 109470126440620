import React from 'react';

const BelowFooter: React.FC = () => {
  return (
    <div className="w-full bg-black border-t border-white/20 px-4 py-8 text-center">
      <p className="text-gray-400 text-[10px] sm:text-xs md:text-sm font-semibold leading-relaxed max-w-4xl mx-auto font-sans mb-4">
        The services offered by PINX Global Markets are provided by a registered business entity based in Czech Republic.
        Trading cryptocurrencies involves a high level of risk and may not be suitable for all investors.
        Please consult with an independent financial advisor if necessary.
        <br />
        PINX Global Markets does not provide investment, tax, or legal advice. Past performance is not indicative of future results.
      </p>

      <div className="pt-4 border-t border-white/20">
        <p className="text-white text-[11px] sm:text-xs font-medium font-sans tracking-wide">
          © 2023–2025 <span className="font-bold">PINXGlobal.com</span>. All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default BelowFooter;
