import React from 'react';

const MenuCategory: React.FC<{ title: string }> = ({ title }) => {
  return (
    <div className="relative w-full flex items-center justify-center my-4 px-5">
      {/* Left Line */}
      <div className="flex-grow h-px bg-gray-300 mr-3" />

      {/* Center Title */}
      <div
        className="text-xl sm:text-2xl md:text-3xl lg:text-4xl text-gray-300 italic font-semibold bg-black px-2 py-1 z-10 inline-block"
        style={{ textShadow: '0 0 8px rgba(255, 255, 255, 0.8)' }}
      >
        {title}
      </div>

      {/* Right Line */}
      <div className="flex-grow h-px bg-gray-300 ml-3" />
    </div>
  );
};

export default MenuCategory;
