import React, { useState } from 'react';
import DropdownItem from './DropdownItem';

const DiscoverDropdown = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex items-center text-white px-2 font-medium h-full cursor-pointer transition-all"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="text-[18px]">Discover</span>
      <svg
        className={`w-4 h-4 ml-1 transition-transform duration-300 ${
          isHovered ? 'rotate-180' : ''
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>

      <div
        className={`absolute top-full left-0 w-[450px] z-50 transition-all duration-500 ease-out overflow-hidden shadow-xl rounded-xl mt-2 ${
          isHovered ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <DropdownItem
          href="/companies"
          icon="/assets/company.png"
          title="Explore Companies"
          subtitle="Discover the listed companies and their market performance."
        />
        <DropdownItem
          href="/governance"
          icon="/assets/hammer.png"
          title="Governance"
          subtitle="Propose, vote, and shape the future of the PINX ecosystem."
        />
      </div>
    </div>
  );
};

export default DiscoverDropdown;
