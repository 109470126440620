import React from 'react';
import Link from 'next/link';
import Image from 'next/image';

interface MenuItemProps {
  href: string;
  label: string;
  icon: string;
  toggleModal: () => void;
  external?: boolean;
}

const MenuItem: React.FC<MenuItemProps> = ({ href, label, icon, toggleModal, external }) => {
    const baseClasses = `
    flex items-center gap-3 w-full px-5 py-3 text-left
    text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl
    bg-gradient-to-b from-white/10 to-white/0
    border-t border-b border-white/10
    transition-all duration-300 ease-in-out
    hover:text-pink-400 hover:translate-y-[2px] hover:from-white/20 hover:to-white/0
  `;
  
  if (external) {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        onClick={toggleModal}
        className={baseClasses}
      >
        <Image src={icon} alt={label} width={30} height={30} />
        {label}
      </a>
    );
  }

  return (
    <Link href={href} passHref legacyBehavior>
      <a onClick={toggleModal} className={baseClasses}>
        <Image src={icon} alt={label} width={30} height={30} />
        {label}
      </a>
    </Link>
  );
};

export default MenuItem;
