import React from 'react';
import Link from 'next/link';
import ConnectWalletButton from '../Account/ConnectWalletButton';
import SupportDropdown from './Dropdown/SupportDropdown';
import TokenInfoDropdown from './Dropdown/TokenInfoDropdown';
import NewsDropdown from './Dropdown/NewsDropdown';
import LearnDropdown from './Dropdown/LearnDropdown';
import TradeDropdown from './Dropdown/TradeDropdown';
import DiscoverDropdown from './Dropdown/DiscoverDropdown';

const LaptopNavbar: React.FC = () => {
  return (
    <div className="flex justify-between items-center w-full h-[60px] px-[12px] bg-gradient-to-b from-[rgba(80,0,0,0.6)] to-[rgba(0,0,0,0.6)] text-white relative z-[1000] shadow-md">
      {/* Left side */}
      <div className="flex items-center h-[60px] relative">
        {/* Logo */}
        <div className="flex justify-center font-bold text-[18px] text-white whitespace-nowrap">
  <Link href="/" passHref>
  <a className="text-white no-underline hover:text-white !important">

      PINX Global
    </a>
  </Link>
</div>


        {/* Separator */}
        <div className="shrink-0 bg-[#D3D3D3] w-[1px] h-[16px] mx-[20px]" />

        {/* Nav Links */}
        <div className="flex items-center h-full">
          <TradeDropdown />
          <DiscoverDropdown />
          <LearnDropdown />
          <NewsDropdown />
          <TokenInfoDropdown />
          <SupportDropdown />
        </div>
      </div>

      {/* Right side */}
      <div className="flex items-center">
        <div className="flex items-center space-x-4">
          {/* Separator */}
          <div className="shrink-0 bg-[#D3D3D3] w-[1px] h-[16px] mx-[20px]" />

          {/* Wallet Button */}
          <ConnectWalletButton setIsModalOpen={undefined} />
        </div>
      </div>
    </div>
  );
};

export default LaptopNavbar;
