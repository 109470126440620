import React, { useEffect, useState } from 'react';
import Link from 'next/link';

const PinxPriceRow = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <div className="flex justify-between items-center w-full h-[60px] px-5">
      <div>
                    {/*
        <Link href="/presale" passHref>
          <span className="inline-flex items-center px-4 py-2 bg-gradient-to-b from-white/20 to-white/0 rounded-xl">
            <span
              className="text-base sm:text-lg md:text-xl lg:text-2xl font-semibold"
              style={{ color: '#00ff00', textShadow: '0 0 8px rgba(0, 255, 0, 0.7)' }}
            >
              BUY $PINX
            </span>
            
          </span>
        </Link>
        */}
      </div>
      <span className="text-white text-base sm:text-lg md:text-xl lg:text-2xl">
        {time.toLocaleTimeString()}
      </span>
    </div>
  );
};

export default PinxPriceRow;
