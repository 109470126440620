import React from 'react';
import Link from 'next/link';

interface MobileMenuProps {
  toggleModal: () => void;
}

const MobileNavbar: React.FC<MobileMenuProps> = ({ toggleModal }) => {
  return (
    <div className="flex justify-between items-center w-full h-[60px] px-3 bg-gradient-to-b from-[rgba(80,0,0,0.6)] to-[rgba(0,0,0,0.6)] text-white relative z-[1000] shadow-md">
      <div className="flex items-center">
        <Link href="/" passHref>
          <span className="font-bold text-white hover:scale-110 transition-transform duration-300
            text-xl sm:text-2xl md:text-3xl lg:text-4xl whitespace-nowrap
          ">
            PINX Global
          </span>
        </Link>
      </div>

      <div
        className="cursor-pointer text-2xl sm:text-3xl md:text-4xl lg:text-5xl"
        onClick={toggleModal}
      >
        &#9776;
      </div>
    </div>
  );
};

export default MobileNavbar;
