import React from 'react';
import { FaTwitter, FaTelegramPlane, FaYoutube, FaApple } from 'react-icons/fa';
import Image from 'next/image';
import Link from 'next/link';

const LaptopFooter: React.FC = () => {
  return (
    <>
      <div className="w-full bg-gradient-to-b from-[rgba(80,0,0,0.6)] to-[rgba(0,0,0,0.6)] border-t border-white/20 shadow-md py-8 px-4">
        <div className="max-w-[1400px] mx-auto flex flex-wrap justify-between items-start gap-10">
          {/* Logo Section */}
          <div className="flex flex-col items-center text-center">
            <div className="logo-background">
              <Image
                src="/assets/pinx-circle-logo.png"
                alt="PINX Logo"
                width={180}
                height={180}
                className="rounded-full"
              />
            </div>
            <p className="!text-white font-bold text-base mt-4">
              Your Gateway to Decentralized Finance
            </p>

            <Link href="https://apps.apple.com/us/app/pinx-global-markets/id6670228875" passHref legacyBehavior>
              <a className="mt-4 mb-2 w-52 py-2.5 !text-white text-sm bg-gradient-to-b from-zinc-800 to-black border border-white/20 rounded-lg backdrop-blur transition hover:from-zinc-700 hover:to-zinc-900 flex items-center justify-center">
                <FaApple className="mr-2 text-xl" /> Download on the App Store
              </a>
            </Link>

            <div className="flex gap-6 mt-4 !text-white text-3xl">
              <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer">
                <FaTwitter />
              </a>
              <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer">
                <FaTelegramPlane />
              </a>
              <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer">
                <FaYoutube />
              </a>
            </div>
          </div>

          {/* $PINX Info */}
          <div className="flex flex-col items-center !text-white gap-3">
            <h3 className="text-lg font-bold underline !text-white">$PINX Info</h3>
            <Link href="/tokenomics" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Whitepaper</a>
            </Link>
            <Link href="/tokenomics" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Tokenomics</a>
            </Link>
            <Link href="/roadmap" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Roadmap</a>
            </Link>
          </div>

          {/* Learn */}
          <div className="flex flex-col items-center !text-white gap-3">
            <h3 className="text-lg font-bold underline !text-white">Learn</h3>
            <Link href="/learn" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Learn</a>
            </Link>
            <Link href="/quizzes" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Quizzes</a>
            </Link>
          </div>

          {/* Social Links */}
          <div className="flex flex-col items-center !text-white gap-3">
            <h3 className="text-lg font-bold underline !text-white">Social Links</h3>
            <a href="https://x.com/pinxtoken" target="_blank" rel="noopener noreferrer" className="hover:underline font-bold !text-white">
              Twitter
            </a>
            <a href="https://t.me/pinxglobalmarket" target="_blank" rel="noopener noreferrer" className="hover:underline font-bold !text-white">
              Telegram
            </a>
            <a href="https://www.youtube.com/@pinxglobalmarkets" target="_blank" rel="noopener noreferrer" className="hover:underline font-bold !text-white">
              YouTube
            </a>
          </div>

          {/* Discover */}
          <div className="flex flex-col items-center !text-white gap-3">
            <h3 className="text-lg font-bold underline !text-white">Discover</h3>
            <Link href="/companies" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Companies</a>
            </Link>
            <Link href="/news" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">News</a>
            </Link>
            <Link href="/news" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Governance</a>
            </Link>
          </div>

          {/* About Us */}
          <div className="flex flex-col items-center !text-white gap-3">
            <h3 className="text-lg font-bold underline !text-white">About Us</h3>
            <Link href="/about" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">About</a>
            </Link>
            <Link href="/support" passHref legacyBehavior>
              <a className="hover:underline font-bold !text-white">Support</a>
            </Link>
            <span className="invisible font-bold !text-white">About</span>
          </div>
        </div>
      </div>

      {/* Keep this one style scoped via styled-jsx */}
      <style jsx>{`
        .logo-background {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          box-shadow: 0 0 20px 8px rgba(249, 0, 143, 0.6), 0 0 40px rgba(139, 33, 223, 0.5);
        }
      `}</style>
    </>
  );
};

export default LaptopFooter;
