import React, { useState } from 'react';
import DropdownItem from './DropdownItem';

const TradeDropdown = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="relative flex items-center text-white px-2 font-medium h-full cursor-pointer transition-all"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <span className="text-[18px]">Trade</span>
      <svg
        className={`w-4 h-4 ml-1 transition-transform duration-300 ${
          isHovered ? 'rotate-180' : ''
        }`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
      >
        <path d="M5 8l5 5 5-5H5z" />
      </svg>

      <div
        className={`absolute top-full left-0 w-[500px] z-50 transition-all duration-500 ease-out overflow-hidden shadow-xl rounded-xl mt-2 ${
          isHovered ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
        }`}
      >
        <DropdownItem
          href="/swap"
          icon="/assets/swap.gif"
          title="Trade Tokenized Assets"
          subtitle="Swap tokenized stocks seamlessly using deep liquidity pools for efficient trades."
        />
        <DropdownItem
          href="/TTD"
          icon="/assets/lock-lines.png"
          title="Tokenised Trading Desk"
          subtitle="Access advanced tools for escrow-based, on-demand trading securely and efficiently."
        />
        <DropdownItem
          href="/wallet"
          icon="/assets/digital-wallet.png"
          title="Digital Wallet"
          subtitle="Securely manage your funds, transactions, and digital assets with ease."
        />
        <DropdownItem
          href="/demo"
          icon="/assets/buy.png"
          title="Demo Trading"
          subtitle="Refine your skills by trading with Solana tokens in a risk-free environment."
        />
      </div>
    </div>
  );
};

export default TradeDropdown;
